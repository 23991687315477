import axios from "axios";
import queryString from "query-string";
import { getToken } from '../utils/Abstractions/Functions.jsx';

const Api = axios.create({ baseURL: process.env.REACT_APP_HOST });

const Companie = { acess: 2 };
const Accredited = { acess: 3 };
const Administrator = { acess: 0 };

// -------------------------- ADMINISTRATIVOS -------------------------- \\

const sendPush = async (data) => await Api.post('/send-message', data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getInformations = async () => await Api.get(`/send-list`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getListOfCharges = async () => await Api.get("/listar-cobrancas", { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postNewItemHistory = async (data) => await Api.post('/add-history', data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getListHistory = async (dataQuery) => await Api.get(`/list-history/${dataQuery}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getVoucherList = async (dataQuery) => await Api.get(`/administrativo/guias-emitidas?${queryString.stringify(dataQuery)}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getVoucherReport = async (dataQuery) => await Api.get(`/administrativo/guias-emitidas-report?${queryString.stringify(dataQuery)}`, { responseType: 'blob', headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getVoucherToPay = async (dataQuery) => await Api.get(`/administrativo/guias-emitidas-to-pay?${queryString.stringify(dataQuery)}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getVoucherToReceive = async (dataQuery) => await Api.get(`/administrativo/guias-emitidas-to-receive?${queryString.stringify(dataQuery)}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getForecastReport = async (dataQuery) => await Api.get(`/forecast-report?${queryString.stringify(dataQuery)}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postUpdateReceipt = async (id, data) => Api.post(`/atualizar-cobranca/${id}`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const suspendAccredited = async (id) => await Api.delete(`/administrativo/credenciado/${id}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postUpdateAccredited = async (id, data) => await Api.post(`/administrativo/credenciado/update/${id}`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getAccreditedInfo = async (id) => await Api.get(`/administrativo/credenciado/info/${id}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postAccreditedInfo = async (id, data) => await Api.post(`/administrativo/credenciado/info/${id}`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const removeAccreditedInfo = async (id, data) => await Api.get(`/administrativo/credenciado/info/delete/${id}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postAccredited = async (data) => await Api.post("/administrativo/credenciado/cadastro", data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postUpdateAllEmployees = async (id, data) => await Api.post(`/atualizar-parcelamentos/${id}`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getReceiptCancel = async (id, name, text) => await Api.get(`/cancelar-guia/${id}/${name}/${text}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getRamicashList = async () => await Api.get(`/ramicash-list`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getRamicashReceipt = async (ramicash_id) => await Api.get(`/ramicash/receipt/${ramicash_id}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postRamicash = async (data) => await Api.post(`/ramicash`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postRecisao = async (data) => await Api.post(`/ramicash`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postRamicashQRCode = async (data) => await Api.post(`/ramicash/qrcode`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getPontosRamicard = async () => await Api.get(`/pontoscredenciados`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getHistoricEmployee = async (id) => await Api.post(`/historico/${id}`, {}, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const getAdministrators = async () => await Api.get(`/administrativo/administradores`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postUpdateAdministrator = async (id, data) => await Api.post(`/administrativo/administrador/${id}`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

const postAdministrator = async (data) => await Api.post(`/administrativo/administrador`, data, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

// -------------------------- PIX - ACESSO ADMINISTRATIVO -------------------------- \\

const getPixPayments = async (dataQuery) => await Api.get(`/administrativo/pix/transactions?${queryString.stringify(dataQuery)}`, { headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });
const postUpdateAllPixSettings = async (data) => await Api.post(`/administrativo/pix/settings/all`, data ,{ headers: { ...Administrator, Authorization: `Bearer ${getToken()}` } });

// -------------------------- EMPRESAS -------------------------- \\

const postLogin = async (dataPost) => await Api.post("/validar-acesso-empresa", dataPost);

const postRegister = async (dataPost) => await Api.post("/primeiro-acesso-empresa", dataPost);

const getInformation = async () => await Api.get(`/empresa`, { headers: { ...Companie, Authorization: `Bearer ${getToken()}` } });

const getReports = async (dataQuery) => await Api.get(`/relatorio-de-consumo?${queryString.stringify(dataQuery)}`, { headers: { ...Companie, Authorization: `Bearer ${getToken()}` } });

const getEmployees = async (companie) => await Api.get(`/funcionarios/${companie}`, { headers: { ...Companie, Authorization: `Bearer ${getToken()}` } });

// -------------------------- CREDENCIADOS -------------------------- \\

const getAccredited = async () => await Api.get(`/credenciado`, { headers: { ...Accredited, Authorization: `Bearer ${getToken()}` } });

const getHistoricAccredited = async (id) => await Api.get(`/historico/${id}`, { headers: { ...Accredited, Authorization: `Bearer ${getToken()}` } });

const getReverse = async (id, protocol_id, fantasy_name) => await Api.get(`/credenciado/${id}/estornar/${protocol_id}/${fantasy_name}`, { headers: { ...Accredited, Authorization: `Bearer ${getToken()}` } });

export default {
  sendPush,
  postLogin,
  getReverse,
  getReports,
  postRegister,
  getEmployees,
  postUpdateAccredited,
  getAccreditedInfo,
  postAccreditedInfo,
  removeAccreditedInfo,
  getAccredited,
  postAccredited,
  getVoucherList,
  getVoucherReport,
  getVoucherToPay,
  getVoucherToReceive,
  getInformation,
  getListHistory,
  getInformations,
  getReceiptCancel,
  getListOfCharges,
  suspendAccredited,
  postUpdateReceipt,
  getForecastReport,
  postNewItemHistory,
  getHistoricAccredited,
  postUpdateAllEmployees,
  getRamicashList,
  getRamicashReceipt,
  postRamicash,
  postRecisao,
  postRamicashQRCode,
  getPontosRamicard,
  getPixPayments,
  postUpdateAllPixSettings,
  getHistoricEmployee,
  getAdministrators,
  postUpdateAdministrator,
  postAdministrator
};
