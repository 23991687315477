// Toast Message
import ToastMsg from '../../Components/Toast';
import { ToastProvider } from 'react-toast-notifications';

import React, { useState, useEffect } from 'react';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import MyAPI from '../../../../connections/Api.jsx';

import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
} from 'react-bootstrap';

const validationSchema = Yup.object().shape({
    nome: Yup.string().required('O campo nome é obrigatório'),
    email: Yup.string().email('O e-mail deve ser válido').required('O e-mail é obrigatório'),
    senha: Yup.string()
        .required('A senha é obrigatória')
        .min(8, 'A senha deve ter pelo menos 8 caracteres'),
    confirmSenha: Yup.string()
        .required('A confirmação de senha é obrigatória')
        .oneOf([Yup.ref('senha'), null], 'As senhas devem coincidir'),

});

export default function EditAdmin(props) {
    const [message, setMessage] = useState([]);
    const [edittingUser, setEdittingUser] = useState({});

    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            nome: '',
            email: '',
            senha: '',
            confirmSenha: '',
            grupo: 'Administradores'
        },
        resolver: yupResolver(validationSchema),
        mode: 'onBlur',
    });

    useEffect(() => {
        if (props.edittingUser) {
            reset(props?.edittingUser)
            setEdittingUser(props?.edittingUser)
        }
    }, [props])

    function isObjectSet(obj) {
        // Verifica se é null, undefined ou não é um objeto
        if (obj === null || obj === undefined || typeof obj !== 'object') {
            return false;
        }

        // Verifica se tem pelo menos uma propriedade própria
        return Object.keys(obj).length > 0;
    }

    async function onSubmit(data) {
        let formData = data;
        formData.grupo = 'Administradores';
        delete formData.confirmSenha;

        if (isObjectSet(edittingUser)) {
            const response = await MyAPI.postUpdateAdministrator(edittingUser._id, formData);
            if (response.status === 200) {
                setMessage([
                    {
                        message: "Usuário atualizado com sucesso",
                        appearance: "success",
                        auto_dismiss: true,
                    },
                ]);
                props.onClose()
                reset({
                    nome: '',
                    email: '',
                    senha: '',
                    confirmSenha: '',
                });
                setEdittingUser()
            }

            if (response.error) {
                setMessage([
                    {
                        message: "Erro ao salvar alterações",
                        appearance: "error",
                        auto_dismiss: true,
                    },
                ]);
            }
        }

        if (!isObjectSet(edittingUser) && formData) {
            const response = await MyAPI.postAdministrator(formData);
            if (response.status === 200) {
                setMessage([
                    {
                        message: "Usuário salvo com sucesso.",
                        appearance: "success",
                        auto_dismiss: true,
                    },
                ]);
                props.onClose()
                reset({
                    nome: '',
                    email: '',
                    senha: '',
                    confirmSenha: '',
                });
                setEdittingUser({})
            }
        }
    }

    return (
        <>
            <ToastProvider>
                <ToastMsg msg={message} clear={() => setMessage([])} />
            </ToastProvider>
            <Card
                title={
                    <>
                        <div style={{
                            marginTop: '5px',
                            float: 'left',
                            paddingRight: '20px',
                            borderRight: '2px solid #c5c5c5',
                        }}>
                            Credenciado
                        </div>
                        <Button
                            onClick={() => {
                                props.onClose()
                                reset({
                                    nome: '',
                                    email: '',
                                    senha: '',
                                    confirmSenha: '',
                                })
                                setEdittingUser({})
                            }}
                            style={{
                                backgroundColor: `${'#008000'}`,
                                color: '#FFF',
                                marginLeft: '20px',
                            }}
                            bsStyle='success'
                            simple={true}
                            type='button'
                        >
                            <i className="fa fa-arrow-left" aria-hidden="true" />
                            &nbsp;&nbsp;
                            Voltar
                        </Button>
                    </>
                }
                content={
                    <div style={{ margin: 15 }}>
                        <Row>
                            <Col xs={12} md={3}>
                                <FormGroup>
                                    <ControlLabel style={{ color: '#000' }}>
                                        Nome*
                                    </ControlLabel>
                                    <Controller
                                        name="nome"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                type='text'
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                bsClass='form-control'
                                                placeholder='Nome do administrador'
                                            // disabled={true}
                                            />
                                        )}
                                    />
                                    {errors.nome && (
                                        <div style={{ fontSize: '12px', color: 'red', marginTop: '5px', marginLeft: '2px' }}>
                                            {errors.nome.message}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormGroup>
                                    <ControlLabel style={{ color: '#000' }}>
                                        E-mail
                                    </ControlLabel>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                type='text'
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                bsClass='form-control'
                                                placeholder='E-mail'
                                                onError={(e) => field.onError(e)}
                                            // disabled={true}
                                            />
                                        )}
                                    />
                                    {errors.email && (
                                        <div style={{ fontSize: '12px', color: 'red', marginTop: '5px', marginLeft: '2px' }}>
                                            {errors.email.message}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormGroup>
                                    <ControlLabel style={{ color: '#000' }}>
                                        Senha
                                    </ControlLabel>
                                    <Controller
                                        name="senha"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                type='password'
                                                {...field}
                                                bsClass='form-control'
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                placeholder='Digite uma senha'
                                            />
                                        )}
                                    />
                                    {errors.senha && (
                                        <div style={{ fontSize: '12px', color: 'red', marginTop: '5px', marginLeft: '2px' }}>
                                            {errors.senha.message}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormGroup>
                                    <ControlLabel style={{ color: '#000' }}>
                                        Confirme a senha
                                    </ControlLabel>
                                    <Controller
                                        name="confirmSenha"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                type='password'
                                                {...field}
                                                bsClass='form-control'
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                placeholder='Confirme a senha senha'
                                            />
                                        )}
                                    />
                                    {errors.confirmSenha && (
                                        <div style={{ fontSize: '12px', color: 'red', marginTop: '5px', marginLeft: '2px' }}>
                                            {errors.confirmSenha.message}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ flexDirection: 'row-reverse' }}>
                            <Button
                                onClick={handleSubmit(onSubmit)}
                                bsStyle="success"
                                variant="contained"
                                style={{
                                    border: 0,
                                    color: "white",
                                    borderRadius: 3,
                                    fontSize: "14px",
                                    marginLeft: '20px',
                                    marginBottom: "10px",
                                    backgroundColor: `${'#008000'}`,
                                    boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                }}
                            >
                                <i className="fa fa-plus" aria-hidden="true" />
                                &nbsp;&nbsp;
                                Salvar
                            </Button>
                        </Row>
                    </div>
                }
                className="px-5"
            />
        </>
    );
};
