import React from "react";
import ReactTable from "react-table";
import capitalize from "capitalize-pt-br";
import Loading from '../../../Components/Loading';
import MyAPI from "../../../../../connections/Api.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import Notifications from "../../../Components/Toast.jsx";
import Card from "../../../../../components/Card/Card.jsx";
import Button from "../../../../../components/CustomButton/CustomButton.jsx";

import { DateTime } from "luxon";

import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { DatePikerConfig } from "../../../../../utils/Abstractions/Variables.jsx";
import { anyIso, isoShortDate } from "../../../../../utils/Abstractions/Functions.jsx";

import { MultiSelect } from "primereact/multiselect";
import { ToastProvider } from "react-toast-notifications";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { printer } from "../../../../../utils/Relatorios.js";

import RamicashReceipt from './RamicashReceipt';

import { checkPermissoes } from "../../../../../services/autenticacoes.jsx";
import * as S from './Styles';

export default function Guias({ goTo }) {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [selected, setSelect] = React.useState([]);
  const [selectedSituacoes, setSelectSituacoes] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [onSelectedList, setOnSelectedList] = React.useState(false);
  const [onSelectedSituacoes, setOnSelectedSituacoes] = React.useState(false);

  const [finalDate, setFinalDate] = React.useState("");
  const [initialDate, setInitialDate] = React.useState("");

  const [ramicashReceipt, setRamicashReceipt] = React.useState("");
  const [showReceipt, setShowReceipt] = React.useState(false);

  const nomePermissao = 'guias';

  const cartoes = [
    { name: "Alimentação", code: "AL" },
    { name: "Produtos", code: "PR" },
    { name: "Serviços", code: "SE" },
    { name: "Combustível", code: "CO" },
    { name: "Ramicash", code: "RC" },
    { name: "PIX", code: "PIX" }
  ];

  const situacoes = [
    { name: "Aberta", code: "Aberta" },
    { name: "Adiantada", code: "Adiantada" },
    { name: "Cancelado", code: "Cancelado" },
    { name: "Fechado", code: "Fechado" },
    { name: "Pendente", code: "Pendente" },
    { name: "Rescisao", code: "Rescisao" }
  ];

  const fetchData = async () => {
    let errors = 0

    if (selected.length === 0) {
      errors++
      setError([{ message: "Selecione pelo menos um canal!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (finalDate === '') {
      errors++
      setError([{ message: "Insira o período final!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (initialDate === '') {
      errors++
      setError([{ message: "Insira o período inicial!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    try {
      if (errors === 0) {
        setLoading(true)
        const response = await MyAPI.getVoucherList({ initialDate, finalDate, channels: selected.map(e => e.name), situacoes: selectedSituacoes.map(e => e.name) });
        setData(response.data)
        setLoading(false)
      }
    } catch (e) {
      setError([{ message: "Não foi possível buscar os dados solicitados", appearance: "error", auto_dismiss: true }]);
    }
  }

  const formatDate = (date) => {
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat('dd/MM/yyyy HH:mm:ss');
  }

  const AdicionarTaxa = (valor, canal) => {
    if (canal === "Alimentação") {
      return (Number(valor * 0.05) + Number(valor))
        .toFixed(2)
        .replace(".", ",");
    } else if (canal === "Combustível") {
      return (Number(valor * 0.05) + Number(valor))
        .toFixed(2)
        .replace(".", ",");
    }
  };

  async function ReceiptCancel(id) {
    try {
      const answer = window.prompt(`Por qual motivo deseja cancelar esta guia?`)

      if (answer !== null) {
        if (answer.length > 0) {
          await MyAPI.getReceiptCancel(id, localStorage.getItem('Name'), `${answer}`);

          setError([{ message: "Guia cancelada com sucesso!", appearance: "success", auto_dismiss: true }]);

          setTimeout(() => window.location.reload(), 2000);
        } else {
          setError([{ message: "Insira um motivo para o cancelamento", appearance: "error", auto_dismiss: true }]);
        }
      }
    } catch (e) {
      setError([{ message: "Não foi possível excluir esta guia", appearance: "error", auto_dismiss: true }]);
    }
  }

  async function ShowReceipt(ramicash_id) {
    try {
      const { data } = await MyAPI.getRamicashReceipt(ramicash_id);
      setRamicashReceipt("");

      if ( data.file ) {
        setRamicashReceipt(data.file);
        setShowReceipt(true);
      } else {
        throw 'Anexo não gravado';
      }
    } catch (e) {
      setError([{ message: "Recibo do PIX não gravado na base.", appearance: "error", auto_dismiss: true }]);
    }
  }

  const downloadReport = async (fileName) => {
    try {
      const response = await MyAPI.getVoucherReport({
        initialDate,
        finalDate,
        channels: selected.map((e) => e.name),
        situacoes: selectedSituacoes.map(e => e.name)
      });
      
      if (response.error) {
        alert(response.message);
      } else {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${fileName}.csv`;
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (e) {
      setError([
        {
          message: "Erro ao baixar o arquivo da empresa!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  }

  return (
    <div className="content" style={{ marginTop: 25 }}>      
      <RamicashReceipt show={showReceipt} closeModal={() => setShowReceipt(false)} data={ramicashReceipt} />
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo("Menu")}
                    style={{ backgroundColor: "#008000", color: "#FFF" }}
                    bsStyle="success"
                    simple={true}
                    type="button"
                    bsSize="sm"
                  >
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true" /> Menu
                  </Button>
                  <br />
                  <p align="center" style={{ fontSize: 20 }}>
                    Guias Emitidas
                  </p>
                  <div style={{ marginTop: "10px" }}> </div>
                </>
              }
              content={
                <>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período inicial
                        </ControlLabel>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          value={anyIso(initialDate, "dd/MM/yyyy")}
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) =>
                            setInitialDate(isoShortDate(e, "dd/MM/yyyy"))
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período Final
                        </ControlLabel>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          value={anyIso(finalDate, "dd/MM/yyyy")}
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) =>
                            setFinalDate(isoShortDate(e, "dd/MM/yyyy"))
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <ControlLabel style={{ color: "#000" }}>
                        Cartões
                      </ControlLabel>
                      <div
                        onMouseOut={() => setOnSelectedList(false)}
                        onMouseOver={() => setOnSelectedList(true)}
                      >
                        <MultiSelect
                          optionLabel="name"
                          value={selected}
                          style={{
                            height: 40,
                            width: "100%",
                            color: "gray",
                            opacity: !onSelectedList ? 0.3 : 1,
                          }}
                          options={cartoes}
                          placeholder={`Cartões Ramicard`}
                          onChange={(e) => setSelect(e.value)}
                        />
                      </div>
                    </Col>

                    <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Situação
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedSituacoes(false)}
                      onMouseOver={() => setOnSelectedSituacoes(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selectedSituacoes}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedSituacoes ? 0.3 : 1,
                        }}
                        options={situacoes}
                        placeholder={`Situações das Guias`}
                        onChange={(e) => setSelectSituacoes(e.value)}
                      />
                    </div>
                  </Col>

                    <Col md={1}>
                      <S.WarapperButtons>
                        <Button
                          onClick={() => fetchData()}
                          style={{
                            backgroundColor: "#008000",
                            color: "#FFF",
                            fontSize: 16,
                            width: 116,
                            marginRight: 15
                          }}
                          bsStyle="success"
                          simple
                          type="button"
                          bsSize="sm"
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Pesquisar
                        </Button>
                      </S.WarapperButtons>
                    </Col>
                        {data.length > 0 ? 
                        <Col md={1}>
                          <S.WarapperButtons>
                            <Button
                              onClick={() => downloadReport('guias-emitidas.csv')
                              }
                              style={{
                                backgroundColor: `${"#008000"}`,
                                color: "#FFF",
                                marginLeft: "10px",
                              }}
                              bsStyle="success"
                              simple={true}
                              type="button"
                            >
                              {" "}
                              <i
                                className="fa fa-file-text-o"
                                aria-hidden="true"
                              />{" "}
                              Exportar Planilha
                            </Button>
                          </S.WarapperButtons>
                        </Col>
                        
                        : null}
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
      {isLoading ?
        <Loading Height='400px' />
        :
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <div align="center" style={{ margin: 15 }}>
                      <ReactTable
                        data={data.map((prop, key) => {
                          return {
                            id: key,
                            credenciado: `${prop.credenciado_id
                              ? prop.credenciado_id.nome_fantasia.toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            empresa: `${prop.empresa_responsavel_id
                              ? prop.empresa_responsavel_id.nome_fantasia.toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            funcionario: `${prop.usuario_id
                              ? capitalize(prop.usuario_id.nome).toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            situacao: `${prop.situacao.toUpperCase()}`,
                            confirmacao: prop.horario_confirmacao,
                            canal: `${prop.canal}`,
                            valor: `${prop.valor
                              ? `R$ ${prop.canal === "Alimentação" ||
                                prop.canal === "Combustível"
                                ? AdicionarTaxa(
                                  prop.taxa_funcionario
                                    ? (
                                      Number(prop.taxa_funcionario) *
                                      prop.valor
                                    ).toFixed(2)
                                    : (
                                      prop.valor / prop.parcelas
                                    ).toFixed(2),
                                  prop.canal
                                )
                                : prop.taxa_funcionario
                                  ? (
                                    Number(prop.taxa_funcionario) *
                                    prop.valor
                                  )
                                    .toFixed(2)
                                    .replace(".", ",")
                                  : (prop.valor / prop.parcelas)
                                    .toFixed(2)
                                    .replace(".", ",")
                              }`
                              : `R$ 0,00`
                              }`,
                            parcela: `${prop.parcela_atual}/${prop.parcelas}`,
                            acoes: (
                              <div className="actions-center">
                                { prop.canal === 'Ramicash' ? 
                                <OverlayTrigger placement="top" overlay={<Tooltip id="cancel_tooltip">Comprovante PIX</Tooltip>}>
                                  <Button
                                    onClick={() => ShowReceipt(prop.ramicash_id)}
                                    bsStyle="success"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <i
                                      className="fa fa-file-image-o"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </OverlayTrigger>                                
                                : <></> }
                                <OverlayTrigger placement="top" overlay={<Tooltip id="remove_tooltip">Extrato Completo</Tooltip>}>
                                  <Button
                                    onClick={() => {
                                      printer(
                                        prop._id,
                                        prop.canal,
                                        prop.horario_confirmacao,
                                        prop.valor,
                                        prop.credenciado_id ? prop.credenciado_id.nome_fantasia.split(' ').filter(e => e !== ' ').length > 0 ? prop.credenciado_id.nome_fantasia : prop.credenciado_id.razao_social : '',
                                        "Ramicard",
                                        prop.empresa_responsavel_id?.nome_fantasia || '',
                                        prop.usuario_id.nome,
                                        prop.parcelas,
                                        prop.observacao,
                                        false,
                                        false,
                                        prop.taxa_funcionario
                                      )
                                    }
                                    }
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <i
                                      className="fa fa-file-text-o"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </OverlayTrigger>
                                { checkPermissoes(nomePermissao, 'delete') ? 
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="cancel_tooltip">Cancelar</Tooltip>}>
                                    <Button
                                      onClick={() => ReceiptCancel(prop._id)}
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="sm"
                                    >
                                      <i
                                        className="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  </OverlayTrigger>
                                : <></> }                                
                              </div>
                            ),
                          };
                        })}
                        filterable
                        columns={[
                          {
                            Header: "Credenciado",
                            accessor: "credenciado",
                          },
                          {
                            Header: "Empresa",
                            accessor: "empresa",
                          },
                          {
                            Header: "Funcionário",
                            accessor: "funcionario",
                          },
                          {
                            Header: "Situação",
                            accessor: "situacao",
                          },
                          {
                            Header: "Confirmação",
                            accessor: "confirmacao",
                            sortMethod: (a, b) => {
                              const dateA = new Date(a).getTime();
                              const dateB = new Date(b).getTime();
                              return dateA - dateB;
                            },
                            Cell: ({ value }) => formatDate(value),
                          },
                          {
                            Header: "Canal",
                            accessor: "canal",
                          },
                          {
                            Header: "Valor",
                            accessor: "valor",
                          },
                          {
                            Header: "Parcela",
                            accessor: "parcela",
                          },
                          {
                            Header: "",
                            accessor: "acoes",
                            filterable: false,
                            sortable: false,
                          },
                        ]}
                        showPaginationBottom={false}
                        defaultPageSize={data.length <= 10 ? 10 : 50}
                        showPaginationTop={true}
                        className="-striped -highlight text-center"
                      />
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      }
    </div>
  );
}
