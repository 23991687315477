import React, { useState } from 'react';
import { Col, Table, Pagination, Button } from 'react-bootstrap';
import api from "../../../../services/api.jsx";
import { getToken, checkPermissoes } from "../../../../services/autenticacoes.jsx";
import capitalize from 'capitalize-pt-br';
import PushModal from '../../Components/PushModal/index.jsx';
import Card from '../../../../components/Card/Card.jsx';
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker.jsx';
import { makeStyles } from "@material-ui/core/styles";
import { PixTransacoesXlsx } from '../../../../services/Excel/Exportacoes-xlsx.jsx';


export default function Settings({ onFilter, data, refresh }) {
  const headers = ['Status', 'Funcionário', 'Solicitado em', 'Chave Pix', 'Valor'];

  const classes = useStyles();

  // const [showHide, setShowHide] = useState(false);

  const handleDateFilter = ({ startDate, endDate }) => {
    onFilter({ startDate, endDate })
    refresh()
  };

  async function handleSaveCompleteListWithPixTransactions() {
    try {
      const { data } = await api.get(
        `/administrativo/lista-pix`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (data.error) {
        alert(data.message);
      } else {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'lista-pix.csv';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const formatToReal = (valor) => {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  const bodyData = () => {
    return currentItems.length === 0 ? (
      <tr>
        <td colSpan={headers.length} style={{ fontSize: 16 }}>
          Nenhuma transação registrada
        </td>
      </tr>
    ) : (
      currentItems.map((pixPayment, key) => (
        <tr key={key}>
          <td>
            <div className={pixPayment?.status == "Pago" ? classes.paidStatus : 'bg-danger text-white rounded'}>
              {pixPayment?.status || ''}
            </div>
          </td>
          <td>{pixPayment?.usuario?.nome || ''}</td>
          <td>{pixPayment?.created_at || ''}</td>
          <td>{pixPayment?.pix_code || ''}</td>
          <td>{formatToReal(pixPayment?.amount) || ''}</td>
        </tr>
      ))
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.length > 0 ? data.slice(indexOfFirstItem, indexOfLastItem) : [];

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Manipula a mudança de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };


  return (
    <>
      <Card
        headerContent={
          <>
            <div className='d-flex flex-row '>
              <Col md={8}>
                <h4>Transações</h4>
              </Col>
              <Col className='d-flex flex-column justify-content-end' md={4}>
                <DateRangePicker onFilterApply={handleDateFilter} />
                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                  <Button style={{width: '78px'}} bsStyle="success" onClick={handleSaveCompleteListWithPixTransactions} >.CSV</Button>

                </div>
                <br/>
              </Col>
            </div>
            <br />

          </>
        }
        content={
          <div align='left' style={{ margin: 20 }}>
            <Table striped hover>
              <thead>
                <tr>
                  {headers.map((prop, key) => {
                    return (
                      <th
                        key={key}
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {prop}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody align='center'>
                {bodyData()}
              </tbody>
            </Table>

            <Pagination className="justify-content-center">
              {renderPaginationItems()}
            </Pagination>
          </div>
        }
      />
    </>
  );
}

const useStyles = makeStyles({
  paidStatus: {
    backgroundColor: '#008000',
    color: '#FFFFFF',
    // fontWeight: 'bold',
    borderRadius: 20,
    width: '60%'
  },
  notification: {
    fontSize: 20,
    flex: 1,
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "17%",
  },
});