import React from "react";

import * as S from "./Styles";

import moment from "moment";
import ReactExport from "react-data-export";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { DateTime } from "luxon";
import { DateZone } from "../../utils/Abstractions/Variables.jsx";
import { Formatacao } from "../../utils/Functions/Formatacoes.jsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const FuncionariosXlsx = function ({
  files,
  fileName = "Funcionarios",
  buttonName = ".xlsx",
}) {
  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            marginLeft: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
          simple
          type="button"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          {` ${buttonName}`}
        </Button>
      }
    >
      <ExcelSheet data={files} name={fileName}>
        <ExcelColumn
          label="Código"
          value={(col) => col.empresa_responsavel.registro}
        />
        <ExcelColumn
          label="Empresa Razão Social"
          value={(col) => col.empresa_responsavel.razao_social}
        />
        <ExcelColumn
          label="Empresa Nome Fantasia"
          value={(col) => col.empresa_responsavel.nome_fantasia}
        />
        <ExcelColumn label="Funcionário" value={(col) => col.nome} />
        <ExcelColumn
          label="Data de Nascimento"
          value={(col) => Formatacao("DATA", col.data_nascimento)}
        />
        <ExcelColumn
          label="Celular"
          value={(col) => Formatacao("CEL", col.celular)}
        />
        <ExcelColumn
          label="Telefone"
          value={(col) => Formatacao("TEL", col.telefone)}
        />
        <ExcelColumn label="RG" value={(col) => col.rg} />
        <ExcelColumn label="CPF" value={(col) => Formatacao("CPF", col.cpf)} />
        <ExcelColumn label="E-mail" value={(col) => col.email} />
        <ExcelColumn
          label="Whatsapp?"
          value={(col) => (col.whatsapp ? "Sim" : "Não")}
        />
        <ExcelColumn label="Endereço" value={(col) => col.endereco} />
        <ExcelColumn label="Nº" value={(col) => col.numero} />
        <ExcelColumn label="Bairro" value={(col) => col.bairro} />
        <ExcelColumn label="Cidade" value={(col) => col.cidade} />
        <ExcelColumn label="CEP" value={(col) => Formatacao("CEP", col.cep)} />
        <ExcelColumn
          label="Situação"
          value={(col) =>
            col.suspenso.status === undefined
              ? col.suspenso
                ? "Inativo"
                : "Ativo"
              : col.suspenso.status
                ? "Inativo"
                : "Ativo"
          }
        />
        <ExcelColumn
          label="Limite Consumo"
          value={(col) => col.ramicard.limite}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const CredenciadosXlsx = function ({ files }) {
  return (
    <ExcelFile
      filename="Credenciados"
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            marginLeft: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
          simple
          type="button"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          .xlsx
        </Button>
      }
    >
      <ExcelSheet data={files} name="Credenciados">
        <ExcelColumn label="Código" value={(col) => col.registro} />
        <ExcelColumn label="Razão Social" value={(col) => col.razao_social} />
        <ExcelColumn label="Nome Fantasia" value={(col) => col.nome_fantasia} />
        <ExcelColumn label="Proprietário" value={(col) => col.proprietario} />
        <ExcelColumn
          label="Celular"
          value={(col) => Formatacao("CEL", col.celular)}
        />
        <ExcelColumn
          label="Whatsapp?"
          value={(col) => (col.whatsapp ? "Sim" : "Não")}
        />
        <ExcelColumn
          label="Telefone"
          value={(col) => Formatacao("TEL", col.telefone)}
        />
        <ExcelColumn
          label="CPF/CNPJ"
          value={(col) =>
            String(col.cnpj_cpf).length <= 11
              ? Formatacao("CPF", col.cnpj_cpf)
              : Formatacao("CNPJ", col.cnpj_cpf)
          }
        />
        <ExcelColumn label="E-mail" value={(col) => col.email} />
        <ExcelColumn label="Endereço" value={(col) => col.endereco} />
        <ExcelColumn label="Nº" value={(col) => col.numero} />
        <ExcelColumn label="Bairro" value={(col) => col.bairro} />
        <ExcelColumn label="Cidade" value={(col) => col.cidade} />
        <ExcelColumn label="CEP" value={(col) => Formatacao("CEP", col.cep)} />
        <ExcelColumn label="Situação" value={(col) =>  col.ativo ? 'Ativo' : 'Inativo' } />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const EmpresasXlsx = function ({ files }) {
  return (
    <ExcelFile
      filename="Empresas"
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            marginLeft: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
          simple
          type="button"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          .xlsx
        </Button>
      }
    >
      <ExcelSheet data={files} name="Empresas">
        <ExcelColumn label="Código" value={(col) => col.registro} />
        <ExcelColumn label="Razão Social" value={(col) => col.razao_social} />
        <ExcelColumn label="Nome Fantasia" value={(col) => col.nome_fantasia} />
        <ExcelColumn label="Proprietário" value={(col) => col.proprietario} />
        <ExcelColumn
          label="Celular"
          value={(col) => Formatacao("CEL", col.celular)}
        />
        <ExcelColumn
          label="Whatsapp?"
          value={(col) => (col.whatsapp ? "Sim" : "Não")}
        />
        <ExcelColumn
          label="Telefone"
          value={(col) => Formatacao("TEL", col.telefone)}
        />
        <ExcelColumn
          label="CPF/CNPJ"
          value={(col) => Formatacao("CNPJ", col.cnpj)}
        />
        <ExcelColumn label="E-mail" value={(col) => col.email} />
        <ExcelColumn label="Endereço" value={(col) => col.endereco} />
        <ExcelColumn label="Nº" value={(col) => col.numero} />
        <ExcelColumn label="Bairro" value={(col) => col.bairro} />
        <ExcelColumn label="Cidade" value={(col) => col.cidade} />
        <ExcelColumn label="CEP" value={(col) => Formatacao("CEP", col.cep)} />
        <ExcelColumn label="Fechamento" value={(col) => col.dia_fechamento} />
        <ExcelColumn label="Pagamento" value={(col) => col.dia_pagamento} />
      </ExcelSheet>
    </ExcelFile>
  );
};

const formatToReal = (valor) => {
  return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export const PixTransacoesXlsx = function ({ files }) {
  console.log(files)
  return (
    <ExcelFile
      filename="Pix"
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            marginLeft: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
          simple
          type="button"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          .xlsx
        </Button>
      }
    >
      <ExcelSheet data={files} name="Pix">
        <ExcelColumn label="Status" value={(col) => col.status} />
        <ExcelColumn label="Funcionário" value={(col) => col.nome} />
        <ExcelColumn label="Solicitado em" value={(col) => col.created_at} />
        <ExcelColumn label="Valor" value={(col) => formatToReal(col.amount)} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const PagarCredenciadosXlsx = function ({ files }) {
  return (
    <ExcelFile
      filename={`Pagamento Credenciados (${moment().format("DD-MM hh-mm")})`}
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            width: 116,
            justifyContent: "center",
            alignItems: "center",
          }}
          simple
          type="button"
          bsSize="sm"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" /> Baixar xlsx
        </Button>
      }
    >
      <ExcelSheet dataSet={files} name="Pagamentos à Credenciados" />
    </ExcelFile>
  );
};

export const ReceberEmpresasXlsx = function ({
  files,
  fileName = "Recebimento Empresas",
  buttonName = "Download",
}) {
  return (
    <ExcelFile
      filename={`${fileName} (${DateTime.local()
        .setZone(DateZone)
        .toFormat("dd-MM-yyyy HH-mm")})`}
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            justifyContent: "center",
            borderColor: "#008930",
            alignItems: "center",
            color: "#FFF",
            fontSize: 16,
          }}
          type="button"
          bsSize="sm"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          {` ${buttonName}`}
        </Button>
      }
    >
      <ExcelSheet dataSet={files} name={fileName} />
    </ExcelFile>
  );
};

export const ConsumoDetalhadoXlsx = function ({ files, fileName }) {
  console.log(files);
  console.log(fileName);

  const TaxaAlimentacao = 0.05;

  const AdicionarTaxa = (valor) => valor + valor * TaxaAlimentacao;

  const MesVendido = (data, parcelaAtual) =>
    DateTime.fromFormat(data, "dd/MM/yyyy HH:mm:ss")
      .plus({ months: -(parcelaAtual - 1) })
      .toFormat("dd/MM/yyyy HH:mm:ss");

  const valorTotalEmpresas = () => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };
    const amount = files
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível" || e.canal === "Ramicash"
          ? CartaoAlimentacao(
            e.taxa_funcionario
              ? e.valor * e.taxa_funcionario
              : e.valor / e.parcelas
          )
          : e.taxa_funcionario
            ? e.valor * e.taxa_funcionario
            : e.valor / e.parcelas
      )
      .reduce((total, current) => total + current, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      amount
    ).toFixed(2);
  };

  const GerarArquivo = () => {
    let dataSet = [
      {
        columns: [
          {
            title: "CREDENCIADOS",
            width: { wpx: 250 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "FUNCIONARIO",
            width: { wpx: 250 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "CANAL",
            width: { wpx: 140 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "AUTORIZACAO",
            width: { wpx: 130 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "VENCIMENTO",
            width: { wpx: 130 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "P",
            width: { wpx: 30 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "T",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "VALOR PARCELA",
            width: { wpx: 120 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
        ],
        data: [],
      },
    ];

    files
      .map((item) =>
        item
          ? dataSet[0].data.push([
            {
              value: `${item.credenciado_id.nome_fantasia}`.toLocaleUpperCase(),
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: `${item.usuario_id.nome}`.toLocaleUpperCase(),
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: `${item.canal}`.toLocaleUpperCase(),
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: MesVendido(item.horario_confirmacao, item.parcela_atual),
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: `${DateTime.fromISO(item.data_registro).toFormat(
                "dd/MM/yyyy HH:mm:ss"
              )}`,
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.parcela_atual,
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.parcelas,
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value:
                (item.taxa_funcionario
                  ? item.canal === "Alimentação" ||
                    item.canal === "Combustível"
                    ? AdicionarTaxa(
                      item.valor * Number(item.taxa_funcionario)
                    )
                    : item.valor * Number(item.taxa_funcionario)
                  : item.canal === "Alimentação" ||
                    item.canal === "Combustível"
                    ? AdicionarTaxa(item.valor / item.parcelas)
                    : item.valor / item.parcelas
                ).toFixed(2)
              ,
              style: {
                font: { sz: "11" },
                alignment: { horizontal: "center" },
              },
            },
          ])
          : false
      )
      .filter((e) => e !== false);

    dataSet[0].data.push([
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      {
        value: "Total: ",
        style: {
          font: { sz: "11", bold: true, color: "red" },
          alignment: { horizontal: "right" },
        },
      },
      {
        value: Number(valorTotalEmpresas()),
        style: {
          numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "11", bold: true },
          alignment: { horizontal: "center" },
        },
      },
    ]);

    return dataSet;
  };

  return (
    <ExcelFile
      filename={`${fileName} (${DateTime.now()
        .setZone("America/Sao_paulo")
        .toFormat("dd-MM-yyyy HH-mm")})`}
      element={
        <Button
          type="button"
          bsSize="lg"
          variant="contained"
          style={{
            border: 0,
            borderRadius: 3,
            background: "rgba(35,142,35, .8)",
            boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
            color: "white",
            fontSize: "14px",
          }}
        >
          <S.Icon className="fa fa-file-excel-o" aria-hidden="true" /> Exportar
          planilha
        </Button>
      }
    >
      <ExcelSheet dataSet={GerarArquivo()} name={fileName} />
    </ExcelFile>
  );
};
