import api from "../../services/api.jsx"
import Capitalize from 'capitalize-pt-br'
import Button from "../CustomButton/CustomButton.jsx"

import { Card } from "../Card/Card.jsx"
import React, { useState, useEffect, useReducer } from 'react'
import { Modal, Tab, Tabs } from "react-bootstrap"
import { FormInputs } from "../FormInputs/FormInputs.jsx"
import { getToken } from "../../services/autenticacoes.jsx"
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap"

import MyAPI from "../../connections/Api.jsx";
import Notifications from "../../views/Administrativos/Components/Toast.jsx";

import { ToastProvider } from "react-toast-notifications";

import { CompareStrings } from '../../utils/CompareStrings.js';
import PixSettingsFormByCompany from "../../views/Administrativos/Components/PixSettingsFormByCompany/index.jsx"

const formDadosPixInitialState = {
   percentualRotativo: '',
   maxParcelamento: '',
   habilitado: false,
};

const dadosPixReducer = (state, action) => {
   switch (action.type) {
      case 'UPDATE_FIELD':
         return { ...state, [action.field]: action.value };
      case 'RESET_FORM':
         return formDadosPixInitialState;
      default:
         return state;
   }
};

export default function ModalCredenciados(props) {

   const data = new Date()

   const [error, setError] = useState([]);
   const [dados_empresas, setDados_empresas] = useState([]);
   const [canalPix, setCanalPix] = useState({});

   const [dadosPix, dispatch] = useReducer(dadosPixReducer, formDadosPixInitialState);

   const [servicosativos, setServicosAtivos] = useState([]);
   const [refresh, setRefresh] = useState(false);

   const handleInputFormDadosPixChange = (field, value) => {
      dispatch({ type: 'UPDATE_FIELD', field, value });
   };

   const limparFormDadosPix = () => {
      dispatch({ type: 'RESET_FORM' });
   };

   useEffect(() => {
      async function list() {
         try {
            const respons = await api.get('/canais', { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } });
            setServicosAtivos(respons.data)

         } catch (error) {
            console.log("Ops! Ocorreu um erro.")
         }
      }
      list();
   }, []);

   useEffect(() => {
      async function listarempresas() {
         try {            
            setDados_empresas(props[0] ? props[0] : [])
            if (props[0]) {
               props[0].servicos_autorizados.map((canal) => {
                  if (canal.canal == "PIX") {
                     setCanalPix(canal)
                  }
               })
            }
         } catch (error) {
            alert("Ops! Ocorreu um erro.")
         }
      }
      listarempresas();
   }, [props]);

   useEffect(() => {
      async function refreshScreen() {
         setRefresh(false)
      }
      refreshScreen();
   }, [refresh])

   async function Atualizar(event) {
      event.preventDefault();
      try {
         const data = await api.post(`/administrativo/empresa/${dados_empresas._id}`, dados_empresas, { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } });
         data.status !== 200 ? alert("Ops! Ocorreu um erro.") : alert("Empresa Atualizada Com Sucesso!");
         window.location.reload();
      } catch (err) {
         alert("Ops! Ocorreu um erro.");
      }
   }

   async function Suspender(event) {
      event.preventDefault();
      try {
         if (dados_empresas.ativo) {
            dados_empresas.anotacoes += `\n\n*Suspenso em: ${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}*`
         }
         dados_empresas.ativo = !dados_empresas.ativo
         await api.post(`/administrativo/empresa/${dados_empresas._id}`, dados_empresas, { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } });
         window.location.reload()
      } catch (err) {
         alert("Ops! Ocorreu um erro.")
      }
   }

   function ativo(canal) {
      const filtered = dados_empresas.servicos_autorizados.filter(s => CompareStrings(s.canal, canal.canal) && ( !canal.limite_consumo ? true : s.limite_consumo == canal.limite_consumo ) );      
      return filtered.length > 0;
   }

   function localiza(canal) {      
      const filtered = dados_empresas.servicos_autorizados.map((element, index) => 
         ( CompareStrings(element.canal, canal.canal) && ( !canal.limite_consumo ? true : element.limite_consumo == canal.limite_consumo) ) ? index : undefined).filter(item => item !== undefined)
      return filtered;
   }

   const check = (ativo, canal, parcelas, limite, limite_parcela) => {
      if (!ativo) {
         let canalAtivo = servicosativos.find( c => CompareStrings(c.canal, canal.canal) && ( !c.limite_consumo ? true : c.limite_consumo == canal.limite_consumo) )
         dados_empresas.servicos_autorizados.push({ 
            "canal": canal.canal, 
            "parcelas_max": parcelas, 
            "limite_consumo": Number(limite), 
            "limite_parcela": Number(limite_parcela), 
            "taxa_parcelamento": canalAtivo.taxa_parcelamento, 
            "juros_a_partir": canalAtivo.juros_a_partir 
         })
      } else {
         dados_empresas.servicos_autorizados.splice(dados_empresas.servicos_autorizados.map((element, index) => CompareStrings(element.canal, canal.canal) && ( !element.limite_consumo ? true : element.limite_consumo == canal.limite_consumo) ? index : undefined).filter(item => item !== undefined), 1)
      }
   }

   const usarLimiteCanal = (canal, usarLimite) => {      
      dados_empresas.servicos_autorizados[localiza(canal)].usar_limite_canal = usarLimite
      canal.usar_limite_canal = usarLimite
      setRefresh(true)      
   }

   const updateAllEmployees = async () => {
      try {
         dados_empresas.servicos_autorizados.map( servico => {
            if ( servico.canal === 'Ramicash' ) {
               const idx = servicosativos.findIndex( s => CompareStrings(s.canal, servico.canal) && ( !s.limite_consumo ? true : s.limite_consumo == servico.limite_consumo) );
               if ( idx >= 0 ) {
                  servico['forcar_limite'] = servicosativos[idx]['forcar_limite'];
                  servico['juros_a_partir'] = servicosativos[idx]['juros_a_partir'];
                  servico['parcelas_max'] = servicosativos[idx]['parcelas_max'];
                  servico['limite_consumo'] = servicosativos[idx]['limite_consumo'];
                  servico['limite_parcela'] = servicosativos[idx]['limite_parcela'];
                  servico['usa_limite_canal'] = servicosativos[idx]['usa_limite_canal'];
                  servico['layer_id'] = servicosativos[idx]['layer_id'];
                  servico['taxa_parcelamento'] = servicosativos[idx]['taxa_parcelamento'];
               }
            }
         });

         const response = await MyAPI.postUpdateAllEmployees(dados_empresas._id, { canaisLiberados: dados_empresas.servicos_autorizados });

         alert(response.data.message)
      } catch (e) {
         setError([
            ...error,
            {
               message: "Ocorreu um problema na solicitação, verifique os dados e tente novamente!",
               appearance: "error",
               auto_dismiss: false,
            },
         ]);
      }
   }

   return (
      <Modal {...props} style={{ marginTop: '-15px', width: '100%', margin: 'auto' }} >
         <ToastProvider>
            <Notifications msg={error} clear={() => setError([])} />
         </ToastProvider>
         <Tabs defaultActiveKey="home" style={{ color: 'red' }}>
            <Tab eventKey="home" title="Informações Gerais">
               <div className="content" style={{ marginTop: '10px' }}>
                  <Grid fluid>
                     <Row>
                        <Col md={12} >
                           <Card
                              title={`Informações Gerais${dados_empresas.registro ? ` - ${dados_empresas.registro}` : 'E????'}`}
                              content={
                                 <form onSubmit={Atualizar}>
                                    <FormInputs
                                       {...props}
                                       ncols={["col-md-7", "col-md-5"]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Razão Social",
                                             type: "text",
                                             required: true,
                                             bsClass: "form-control",
                                             placeholder: "Razão Social",
                                             onChange: event => dados_empresas.razao_social = event.target.value,
                                             defaultValue: Capitalize(dados_empresas.razao_social),
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Nome Fantasia",
                                             type: "text",
                                             required: true,
                                             bsClass: "form-control",
                                             placeholder: "Nome Fantasia",
                                             onChange: event => dados_empresas.nome_fantasia = event.target.value,
                                             defaultValue: Capitalize(dados_empresas.nome_fantasia)
                                          }
                                       ]}
                                    />
                                    <FormInputs
                                       ncols={["col-md-6", "col-md-6"]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Inscrição Estadual",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Inscrição Estadual",
                                             onChange: event => dados_empresas.incricao_estadual = event.target.value,
                                             defaultValue: dados_empresas.incricao_estadual
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "CNPJ",
                                             type: "text",
                                             required: true,
                                             bsClass: "form-control",
                                             placeholder: "CNPJ da empres",
                                             onChange: event => dados_empresas.cnpj = event.target.value,
                                             value: dados_empresas.cnpj,
                                             readOnly: true
                                          }
                                       ]}
                                    />
                                    <FormInputs
                                       ncols={["col-md-8", "col-md-4",]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Email",
                                             type: "email",
                                             bsClass: "form-control",
                                             placeholder: "Email",
                                             onChange: event => dados_empresas.email = event.target.value,
                                             defaultValue: dados_empresas.email
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Telefone",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Telefone",
                                             onChange: event => dados_empresas.telefone = event.target.value,
                                             defaultValue: dados_empresas.telefone
                                          }
                                       ]}
                                    />
                                    <Row>
                                       <Col md={6}>
                                          <FormGroup>
                                             <ControlLabel style={{ color: '#000' }}>Proprietário</ControlLabel>
                                             <FormControl
                                                disabled={!dados_empresas.ativo}
                                                type="text"
                                                bsClass="form-control"
                                                placeholder="Proprietário"
                                                onChange={event => dados_empresas.proprietario = event.target.value}
                                                defaultValue={Capitalize(dados_empresas.proprietario)} />
                                          </FormGroup>
                                       </Col>
                                       <Col md={2}>
                                          <FormGroup>
                                             <ControlLabel style={{ color: '#000' }}>Taxa ADM.%</ControlLabel>
                                             <FormControl
                                                disabled={!dados_empresas.ativo}
                                                type="text"
                                                required={true}
                                                bsClass="form-control"
                                                placeholder="Taxa ADM.%"
                                                onChange={event => dados_empresas.taxa_administrativa = event.target.value}
                                                defaultValue={dados_empresas.taxa_administrativa} />
                                          </FormGroup>
                                       </Col>
                                       <Col md={3}>
                                          <FormGroup>
                                             <ControlLabel style={{ color: '#000' }}>Celular</ControlLabel>
                                             <FormControl
                                                disabled={!dados_empresas.ativo}
                                                type="text"
                                                bsClass="form-control"
                                                placeholder="Celular"
                                                onChange={event => dados_empresas.celular = event.target.value}
                                                defaultValue={String(dados_empresas.celular).length === 11 ? `(${String(dados_empresas.celular)[0]}${String(dados_empresas.celular)[1]}) ${String(dados_empresas.celular).slice(2)}` : dados_empresas.celular} />
                                          </FormGroup>
                                       </Col>
                                       <Col md={1}>
                                          <FormGroup>
                                             <ControlLabel style={{ color: '#000' }}>WPP</ControlLabel>
                                             <div style={{ marginTop: "+10px" }}>
                                                <input
                                                   disabled={!dados_empresas.ativo}
                                                   type='checkbox'
                                                   name='q1_myOptions[]'
                                                   id="input_1_0"
                                                   defaultChecked={dados_empresas.whatsapp}
                                                   onClick={() => dados_empresas.whatsapp = !dados_empresas.whatsapp}
                                                />
                                                <label htmlFor="input_1_0"></label>
                                             </div>
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col md={12}>
                                          <FormGroup controlId="formControlsTextarea" style={{ marginBottom: '-30px' }}>
                                             <ControlLabel style={{ color: '#000' }}>Anotações</ControlLabel>
                                             <FormControl
                                                disabled={!dados_empresas.ativo}
                                                rows="3"
                                                componentClass="textarea"
                                                bsClass="form-control"
                                                placeholder="Anotação"
                                                onChange={event => dados_empresas.anotacoes = event.target.value}
                                                defaultValue={dados_empresas.anotacoes}
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                    <Button
                                       disabled={!dados_empresas.ativo}
                                       type="submit"
                                       variant="contained"
                                       bsStyle="info"
                                       style={{
                                          marginTop: '35px',
                                          marginRight: '10px',
                                          background: 'rgba(35,142,35, .8)',
                                          border: 0,
                                          borderRadius: 3,
                                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                          color: 'white',
                                          fontSize: '14px'
                                       }}> Atualizar
                                    </Button>
                                    <Button
                                       onClick={Suspender}
                                       variant="contained"
                                       bsStyle="info"
                                       style={{
                                          marginTop: '35px',
                                          marginRight: '10px',
                                          background: 'rgba(35,142,35, .8',
                                          border: 0,
                                          borderRadius: 3,
                                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                          color: 'white',
                                          fontSize: '14px'
                                       }}> {dados_empresas.ativo ? "Suspender" : "Ativar"}
                                    </Button>
                                 </form>
                              }
                           />
                        </Col>
                     </Row>
                  </Grid>
               </div>
            </Tab>

            <Tab eventKey="profile" title="Endereço">
               <div className="content" style={{ marginTop: '10px' }}>
                  <Grid fluid>
                     <Row>
                        <Col md={12} >
                           <Card
                              title="Informações de Endereço"
                              content={
                                 <form onSubmit={Atualizar}>
                                    <FormInputs
                                       ncols={["col-md-4", "col-md-5", "col-md-3"]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "CEP",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "CEP",
                                             onChange: event => dados_empresas.cep = event.target.value,
                                             defaultValue: dados_empresas.cep
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Cidade",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Cidade",
                                             onChange: event => dados_empresas.cidade = event.target.value,
                                             defaultValue: dados_empresas.cidade
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "UF",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Estado",
                                             onChange: event => dados_empresas.estado = event.target.value,
                                             defaultValue: dados_empresas.estado
                                          }
                                       ]}
                                    />
                                    <FormInputs
                                       ncols={["col-md-8", "col-md-4"]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Endereço",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Endereço",
                                             onChange: event => dados_empresas.endereco = event.target.value,
                                             defaultValue: dados_empresas.endereco
                                          },
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Nº",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Numero",
                                             onChange: event => dados_empresas.numero = event.target.value,
                                             defaultValue: dados_empresas.numero
                                          }
                                       ]}
                                    />
                                    <FormInputs
                                       ncols={["col-md-6"]}
                                       properties={[
                                          {
                                             disabled: !dados_empresas.ativo,
                                             label: "Bairro",
                                             type: "text",
                                             bsClass: "form-control",
                                             placeholder: "Cidade",
                                             onChange: event => dados_empresas.bairro = event.target.value,
                                             defaultValue: dados_empresas.bairro
                                          }
                                       ]}
                                    />
                                    <Button
                                       disabled={!dados_empresas.ativo}
                                       type="submit"
                                       variant="contained"
                                       bsStyle="info"
                                       style={{
                                          marginTop: '35px',
                                          marginRight: '10px',
                                          background: 'rgba(35,142,35, .8)',
                                          border: 0,
                                          borderRadius: 3,
                                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                          color: 'white',
                                          fontSize: '14px'
                                       }}> Atualizar
                                    </Button>
                                    <Button
                                       onClick={Suspender}
                                       variant="contained"
                                       bsStyle="info"
                                       style={{
                                          marginTop: '35px',
                                          marginRight: '10px',
                                          background: 'rgba(35,142,35, .8)',
                                          border: 0,
                                          borderRadius: 3,
                                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                          color: 'white',
                                          fontSize: '14px'
                                       }}> {dados_empresas.ativo ? "Suspender" : "Ativar"}
                                    </Button>
                                 </form>
                              }
                           />
                        </Col>
                     </Row>
                  </Grid>
               </div>
            </Tab>

            <Tab eventKey="services" title="Canais">
               <div className="services" style={{ marginTop: '10px' }}>
                  <Grid fluid>
                     <Row>
                        <Col md={12} >
                           <Card
                              title="Canais"
                              content={
                                 <div>
                                    <form onSubmit={Atualizar}>
                                       <Row>
                                          <Col md={4}>
                                             <ControlLabel style={{ color: '#000' }}>Nome do Serviço</ControlLabel>
                                          </Col>
                                          <Col md={2}>
                                             <ControlLabel style={{ color: '#000' }}>Parcelas</ControlLabel>
                                          </Col>
                                          <Col md={1}>
                                             <ControlLabel style={{ color: '#000' }}>Limite Canal?</ControlLabel>
                                          </Col>
                                          <Col md={2}>
                                             <ControlLabel style={{ color: '#000' }}>Limite R$</ControlLabel>
                                          </Col>
                                          <Col md={2}>
                                             <ControlLabel style={{ color: '#000' }}>Limite Parcela R$</ControlLabel>
                                          </Col>
                                          <Col md={1}>
                                             <ControlLabel style={{ color: '#000' }}>Ativo</ControlLabel>
                                          </Col>

                                       </Row>
                                       {dados_empresas.servicos_autorizados === undefined ? <></> : servicosativos.map((item, key) => (
                                          <Row key={key}>
                                             <Col md={4}>
                                                <FormGroup>
                                                   <FormControl
                                                      type="text"
                                                      disabled={true}
                                                      bsClass="form-control"
                                                      placeholder="Serviço"
                                                      value={item.canal}
                                                      readOnly
                                                   />
                                                </FormGroup>
                                             </Col>
                                             <Col md={2}>
                                                <FormGroup>
                                                   <FormControl
                                                      type="text"
                                                      required={true}
                                                      disabled={!ativo(item)}
                                                      bsClass="form-control"
                                                      placeholder="Parcelas"
                                                      defaultValue={ativo(item) === false ? item.parcelas_max : dados_empresas.servicos_autorizados[localiza(item)].parcelas_max}
                                                      onChange={event => dados_empresas.servicos_autorizados[localiza(item)].parcelas_max = Number(event.target.value)}
                                                   />
                                                </FormGroup>
                                             </Col>
                                             <Col md={1}>
                                                <FormGroup>
                                                   <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                      <input 
                                                         type='checkbox' 
                                                         checked={item.usar_limite_canal || false} 
                                                         disabled={true} 
                                                         onClick={ (event) => { usarLimiteCanal(item, event.target.checked) }}                                                         
                                                      />
                                                   </div>
                                                </FormGroup>
                                             </Col>
                                             <Col md={2}>
                                                <FormGroup>
                                                   <FormControl
                                                      type="text"
                                                      disabled={ (! ativo(item)) || ! item.usar_limite_canal }
                                                      bsClass="form-control"
                                                      placeholder="Limite R$"
                                                      defaultValue={ativo(item) === false ? item.limite_consumo : dados_empresas.servicos_autorizados[localiza(item)].limite_consumo}
                                                      onChange={event => dados_empresas.servicos_autorizados[localiza(item)].limite_consumo = Number(event.target.value)}
                                                   />
                                                </FormGroup>
                                             </Col>
                                             <Col md={2}>
                                                <FormGroup>
                                                   <FormControl
                                                      type="text"
                                                      disabled={ (! ativo(item)) || ! item.usar_limite_canal  }
                                                      bsClass="form-control"
                                                      placeholder="Limite Parcela R$"
                                                      defaultValue={ativo(item) === false ? item.limite_parcela : dados_empresas.servicos_autorizados[localiza(item)].limite_parcela}
                                                      onChange={event => dados_empresas.servicos_autorizados[localiza(item)].limite_parcela = Number(event.target.value)}
                                                   />
                                                </FormGroup>
                                             </Col>
                                             <Col md={1}>
                                                <FormGroup>
                                                   <div style={{ marginLeft: '15px', marginTop: '8px' }}>
                                                      <input 
                                                         type="checkbox" 
                                                         defaultChecked={ativo(item)} 
                                                         onChange={() => check(ativo(item), item, item.parcelas_max, item.limite_consumo, item.limite_parcela, item.usar_limite_canal)}                                                          
                                                      />
                                                   </div>
                                                </FormGroup>
                                             </Col>
                                          </Row>
                                       ))}
                                       <Button
                                          disabled={!dados_empresas.ativo}
                                          type="submit"
                                          variant="contained"
                                          bsStyle="info"
                                          style={{
                                             marginTop: '35px',
                                             marginRight: '10px',
                                             background: 'rgba(35,142,35, .8)',
                                             border: 0,
                                             borderRadius: 3,
                                             boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                             color: 'white',
                                             fontSize: '14px'
                                          }}> Atualizar
                                       </Button>
                                       <Button
                                          onClick={Suspender}
                                          variant="contained"
                                          bsStyle="info"
                                          style={{
                                             marginTop: '35px',
                                             marginRight: '10px',
                                             background: 'rgba(35,142,35, .8)',
                                             border: 0,
                                             borderRadius: 3,
                                             boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                             color: 'white',
                                             fontSize: '14px'
                                          }}> {dados_empresas.ativo ? "Suspender" : "Ativar"}
                                       </Button>
                                       <Button
                                          onClick={updateAllEmployees}
                                          variant="contained"
                                          bsStyle="info"
                                          style={{
                                             marginTop: '35px',
                                             marginRight: '10px',
                                             background: 'rgba(35,142,35, .8)',
                                             border: 0,
                                             borderRadius: 3,
                                             boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                             color: 'white',
                                             fontSize: '14px'
                                          }}> Definir a todos os funcionários
                                       </Button>
                                    </form>
                                 </div>
                              }
                           />
                        </Col>
                     </Row>
                  </Grid>
               </div>
            </Tab>

            <Tab eventKey="pix" title="Pix">
               <div className="content" style={{ marginTop: '10px' }}>
                  <Grid fluid>
                     <Row>
                        <Col md={12} >
                           <Card
                              title="Configurações Pix"
                              content={
                                 <PixSettingsFormByCompany id={dados_empresas._id} actionButtons={true} initialState={canalPix} />
                              }
                           />
                        </Col>
                     </Row>
                  </Grid>
               </div>
            </Tab>

         </Tabs>
      </Modal>
   );
}