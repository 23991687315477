import styled from 'styled-components';
import React, { useState } from 'react';
import Loading from '../../Components/Loading/index.jsx';
import { Grid, Row, Col } from 'react-bootstrap';
import MyAPI from '../../../../connections/Api.jsx';
import { StatsCard } from '../../../../components/StatsCard/StatsCard.jsx';

import Settings from './Settings.jsx';

export const Content = styled.div`
  cursor: pointer;
`;

export default function DashboardPix() {
  const [data, setData] = useState(null);
  const [pixPayments, setPixPayments] = useState(null);
  const [dateFilter, setDateFilter] = useState({});

  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState('Notifications');

  const handleChangeDateRangePicker = ({startDate, endDate}) => {
    setDateFilter({startDate, endDate});
  }

  React.useEffect(() => {
    async function fetchData() {
      try {
        let queryData = "";
        if(dateFilter) {
          queryData = {
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        }
        const { data } = await MyAPI.getPixPayments(queryData);
        setData(data);

      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [refresh]);

  if (data === null) return <Loading Height='950px' />;

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col lg={4}>
              <StatsCard
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Pix realizados'
                statsValue={data?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Total de transações realizadas'
              />
          </Col>
          <Col lg={4}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Número de solicitações'
                statsValue={data?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText={`Total de ${data?.length || 0} solicitações encontradas`}
              />
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-users'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Usuários ativos'
              statsValue={data?.allUsers || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Usuários que realizaram transações pix'
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
              <StatsCard
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Tentativas sucessivas'
                statsValue={data?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Número de tentativas sucessivas'
              />
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-attention'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Transações com erro'
              statsValue={data?.allUsers || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Transações com erro'
            />
          </Col>
          <Col lg={4}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-close-circle'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Transações bloqueadas'
                statsValue={data?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText={`Total de solicitações ${data?.length || 0}`}
              />
          </Col>
          
        </Row>
        <Row>
          <Col md={12}>
                  <Settings
                    refresh={() => setRefresh(!refresh)}
                    onFilter={handleChangeDateRangePicker}
                    data={data}
                  />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
