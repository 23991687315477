import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card/Card.jsx';
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";

import MyAPI from '../../../../connections/Api.jsx';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import capitalize from 'capitalize-pt-br';
import ReactTable from "react-table";
import EditAdmin from './Edit.jsx';



const validationSchema = Yup.object().shape({
    nome: Yup.string().required('O campo nome é obrigatório'),
    email: Yup.string().email('O e-mail deve ser válido').required('O e-mail é obrigatório'),
    senha: Yup.string()
        .required('A senha é obrigatória')
        .min(8, 'A senha deve ter pelo menos 8 caracteres'),
    confirmSenha: Yup.string()
        .required('A confirmação de senha é obrigatória')
        .oneOf([Yup.ref('senha'), null], 'As senhas devem coincidir'),

});


export default function Administrators() {
    const [administrators, setAdministrators] = useState([])
    const [edittingUser, setEdittingUser] = useState({})
    const [pagina, setPagina] = useState(1)

    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            nome: '',
            email: '',
            senha: '',
            confirmSenha: '',
            grupo: 'Administradores'
        },
        resolver: yupResolver(validationSchema),
        mode: 'onBlur',
    });

    const editar = <Tooltip id="edit_tooltip">Editar</Tooltip>;
    const deletarSenha = () => { }

    useEffect(() => {
        getAdministrators()
    }, [])

    async function getAdministrators() {
        const admins = await MyAPI.getAdministrators();
        setAdministrators(admins.data);
    }

    function onEdit(user) {
        setPagina(2)
        setEdittingUser(user)
    }

    function onClose() {
        setPagina(1)
        setEdittingUser({})
    }

    return (
        <div className='content'>
            {
                pagina === 1
                    ?
                    <Card title={
                        <>
                            <div style={{
                                marginTop: '5px',
                                float: 'left',
                                paddingRight: '20px',
                                borderRight: '2px solid #c5c5c5',
                            }}>
                                Administradores
                            </div>
                            <Button
                                onClick={() => setPagina(2)}
                                bsStyle="success"
                                variant="contained"
                                style={{
                                    border: 0,
                                    color: "white",
                                    borderRadius: 3,
                                    fontSize: "14px",
                                    marginLeft: '20px',
                                    marginBottom: "10px",
                                    backgroundColor: `${'#008000'}`,
                                    boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                }}
                            >
                                <i className="fa fa-plus" aria-hidden="true" />
                                &nbsp;&nbsp;
                                Novo Administrador
                            </Button>
                        </>
                    }
                        content={
                            <div>
                                <div align="center" style={{ margin: 15 }}>
                                    <ReactTable
                                        data={administrators.map((prop, key) => {
                                            return {
                                                id: key,
                                                nome: `${capitalize(prop.nome).toUpperCase()}`,
                                                email: capitalize(
                                                    prop.email
                                                ).toUpperCase(),
                                                acoes: (
                                                    <div className="actions-center">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={editar}
                                                            onClick={() =>
                                                                onEdit(prop)
                                                            }
                                                        >
                                                            <Button
                                                                bsStyle="info"
                                                                simple={true}
                                                                type="button"
                                                                bsSize="sm"
                                                            >
                                                                <div style={{ fontSize: 20 }}>
                                                                    <i className="fa fa-edit" />
                                                                </div>
                                                            </Button>
                                                        </OverlayTrigger>

                                                    </div>
                                                ),
                                            };
                                        })}
                                        filterable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                filterMethod: (filter, row) => {
                                                    const filterValue = filter.value.toLowerCase();
                                                    const rowValue = String(row[filter.id]).toLowerCase();
                                                    return rowValue.includes(filterValue);
                                                },
                                            },
                                            {
                                                Header: "NOME",
                                                accessor: "nome",
                                                filterMethod: (filter, row) => {
                                                    const filterValue = filter.value.toLowerCase();
                                                    const rowValue = String(row[filter.id]).toLowerCase();
                                                    return rowValue.includes(filterValue);
                                                },
                                            },
                                            {
                                                Header: "E-MAIL",
                                                accessor: "email",
                                                filterMethod: (filter, row) => {
                                                    const filterValue = filter.value.toLowerCase();
                                                    const rowValue = String(row[filter.id]).toLowerCase();
                                                    return rowValue.includes(filterValue);
                                                },
                                            },
                                            {
                                                Header: "",
                                                accessor: "acoes",
                                                filterable: false,
                                                sortable: false,
                                            },
                                        ]}
                                        defaultPageSize={30}
                                        className="-striped -highlight text-center"
                                    />
                                </div>
                            </div>
                        }
                        className='px-5'>
                    </Card>
                    : (

                        <EditAdmin edittingUser={edittingUser} onClose={onClose} />
                    )
            }
        </div>
    );
}