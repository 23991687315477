import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from "../../../../components/CustomButton/CustomButton.jsx";

const validationSchema = Yup.object().shape({
    pixHabilitado: Yup.boolean().required(),
    // percentualRotativo: Yup.number()
    //     .min(0, 'Deve ser no mínimo 0%')
    //     .max(100, 'Deve ser no máximo 100%')
    //     .required('Percentual rotativo é obrigatório'),
    taxaPix: Yup.number()
        .min(0, 'Deve ser no mínimo 0%')
        .max(100, 'Deve ser no máximo 100%')
        .required('Taxa Pix é obrigatória'),
    taxaPixParcelado: Yup.number()
        .min(0, 'Deve ser no mínimo 0%')
        .max(100, 'Deve ser no máximo 100%')
        .required('Taxa Pix Parcelado é obrigatória'),
    maxParcelamento: Yup.number()
        .min(1, 'Deve ser no mínimo 1 parcela')
        .max(36, 'Deve ser no máximo 36 parcelas')
        .required('Número máximo de parcelas é obrigatório'),
});

export default function PixSettingsFormByEmployee({ sendDataToParent, initialState, actionButtons, ...props }) {
    const [dadosPix, setDadosPix] = useState({});
    const [open, setOpen] = useState(false);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            pixHabilitado: false,
            // percentualRotativo: 40,
            taxaPix: 3,
            taxaPixParcelado: 5,
            maxParcelamento: 10,
        },
        resolver: yupResolver(validationSchema),
    });

    const areAllValuesDefined = (obj) => {
        return Object.values(obj).every(value => value !== undefined);
    }

    useEffect(() => {
        if (areAllValuesDefined(initialState)) {
            let data = {
                ...initialState,
                taxaPix: initialState.taxaPix * 100,
                taxaPixParcelado: initialState.taxaPixParcelado * 100,
            }
            reset(data);
        } else {
            reset({
                pixHabilitado: false,
                // percentualRotativo: 40,
                taxaPix: 3,
                taxaPixParcelado: 5,
                maxParcelamento: 10,
            })
        }
    }, [initialState, reset]);

    const onSubmit = (data) => {
        setDadosPix(data);
        handleOpen();
        // sendDataToParent(data);
    };

    const handleReset = () => {
        reset();
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleConfirm = () => {
        let decimalDadosPix = {
            ...dadosPix,
            taxaPix: dadosPix.taxaPix / 100,
            taxaPixParcelado: dadosPix.taxaPixParcelado / 100,
        }
        sendDataToParent(decimalDadosPix);
        handleClose()
    };


    const formatPercentageValue = (value) => {
        return value ? `${value}%` : '';
    };

    const parsePercentageValue = (value) => {
        return value ? parseFloat(value.replace('%', '')) : '';
    };

    return (
        <form>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle><span style={{ fontSize: '1.75rem' }}>Tem certeza que deseja alterar as configurações pix para esse funcionário?</span></DialogTitle>
                <DialogContent>
                    <div>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <ControlLabel style={{ fontWeight: '400', color: '#000' }}>
                                    Taxa pix (%)*
                                </ControlLabel>
                                <FormControl
                                    type='text'
                                    disabled={true}
                                    defaultValue={dadosPix.taxaPix}
                                    bsClass='form-control'
                                    placeholder='Taxa pix'
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <ControlLabel style={{ fontWeight: '400', color: '#000' }}>
                                    Taxa pix parcelado (%)*
                                </ControlLabel>
                                <FormControl
                                    type='text'
                                    disabled={true}
                                    defaultValue={dadosPix.taxaPixParcelado}
                                    bsClass='form-control'
                                    placeholder='Taxa pix parcelado'
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={12} md={12}>
                            <FormGroup>
                                <ControlLabel style={{ fontWeight: '400', color: '#000' }}>
                                    Percentual rotativo (%)*
                                </ControlLabel>
                                <FormControl
                                    type='text'
                                    disabled={true}
                                    defaultValue={dadosPix.percentualRotativo}
                                    bsClass='form-control'
                                    placeholder='Percentual rotativo'
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <ControlLabel style={{ fontWeight: '400', color: '#000' }}>
                                    Nº máximo de parcelas
                                </ControlLabel>
                                <FormControl
                                    type='number'
                                    disabled={true}
                                    defaultValue={dadosPix.maxParcelamento}
                                    bsClass='form-control'
                                    placeholder='Máximo de parcelas'
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <ControlLabel style={{ fontWeight: '400', color: '#000', display: 'block' }}>
                                    Habilitado
                                </ControlLabel>
                                <input
                                    type='checkbox'
                                    disabled={true}
                                    checked={dadosPix.pixHabilitado}
                                    style={{ marginTop: '5px' }}
                                />
                            </FormGroup>
                        </Col>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancelar</Button>
                    <Button onClick={handleConfirm} color="primary">Confirmar</Button>
                </DialogActions>
            </Dialog>
            <Row>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                            Taxa pix (%)*
                        </ControlLabel>
                        <Controller
                            name="taxaPix"
                            control={control}
                            render={({ field }) => (
                                <FormControl
                                    type='text'
                                    {...field}
                                    value={formatPercentageValue(field.value)}
                                    onChange={(e) => field.onChange(parsePercentageValue(e.target.value))}
                                    bsClass='form-control'
                                    placeholder='Taxa pix'
                                // disabled={true}
                                />
                            )}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                            Taxa pix parcelado (%)*
                        </ControlLabel>
                        <Controller
                            name="taxaPixParcelado"
                            control={control}
                            render={({ field }) => (
                                <FormControl
                                    type='text'
                                    {...field}
                                    value={formatPercentageValue(field.value)}
                                    onChange={(e) => field.onChange(parsePercentageValue(e.target.value))}
                                    bsClass='form-control'
                                    placeholder='Taxa pix parcelado'
                                // disabled={true}
                                />
                            )}
                        />
                    </FormGroup>
                </Col>
                {/* <Col xs={12} md={2}>
                    <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                            Percentual rotativo (%)*
                        </ControlLabel>
                        <Controller
                            name="percentualRotativo"
                            control={control}
                            render={({ field }) => (
                                <FormControl
                                    type='text'
                                    {...field}
                                    value={formatPercentageValue(field.value)}
                                    onChange={(e) => field.onChange(parsePercentageValue(e.target.value))}
                                    bsClass='form-control'
                                    placeholder='Percentual rotativo'
                                />
                            )}
                        />
                    </FormGroup>
                </Col> */}
                <Col xs={12} md={3}>
                    <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                            Nº máximo de parcelas
                        </ControlLabel>
                        <Controller
                            name="maxParcelamento"
                            control={control}
                            render={({ field }) => (
                                <FormControl
                                    type='number'
                                    {...field}
                                    bsClass='form-control'
                                    placeholder='Máximo de parcelas'
                                />
                            )}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <ControlLabel style={{ color: '#000' }}>
                            Habilitado
                        </ControlLabel>
                        <Controller
                            name="pixHabilitado"
                            control={control}
                            render={({ field }) => (
                                <input
                                    type='checkbox'
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    style={{ marginTop: '15px', marginLeft: '25px' }}
                                />
                            )}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {actionButtons && (
                <>
                    <Button
                        bsStyle='info'
                        onClick={handleSubmit(onSubmit)}
                        style={{
                            marginTop: '35px',
                            marginRight: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                        }}
                    >
                        Adicionar
                    </Button>
                </>
            )}
        </form>
    );
}
