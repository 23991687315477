import Capitalize from 'capitalize-pt-br';
import api from '../../../../services/api.jsx';
import React, { useState, useEffect } from 'react';
import CustomButton from '../../Components/Button';
import Card from '../../../../components/Card/Card.jsx';
import HistoricModal from '../../Components/HistoricModal';
import Change from '../../../../assets/img/icon_change.png';
import { getToken, checkPermissoes } from '../../../../services/autenticacoes.jsx';
import Loading from '../../../Administrativos/Components/Loading';
import { Formatacao } from '../../../../utils/Functions/Formatacoes.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { FormInputs } from '../../../../components/FormInputs/FormInputs.jsx';
import { OnlyNumbers } from '../../../../utils/CompareStrings';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Image,
  Radio
} from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from "@material-ui/lab";
import RecisaoModal from '../../Components/RecisaoModal/index.jsx';

import PixSettingsFormByEmployee from '../../Components/PixSettingsFormByEmployee/index.jsx';
import ExtractByEmployee from '../../Components/ExtractByEmployee/index.jsx';
import MyAPI from "../../../../connections/Api.jsx";

import debounce from 'lodash.debounce';


export const Editar = (props) => {
  const data = new Date();
  const nomePermissao = 'funcionarios';
  
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackBar] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [showHideModalRecisao, setShowHideModalRecisao] = useState(false);
  const [dadosRecisao, setDadosRecisao] = useState([]);  

  const [dadosPix, setDadosPix] = useState({});
  const [extract, setExtract] = useState({ value: 0, historic: [] })

  useEffect(() => {
    defineExtract()
  }, [props.dados_funcionario._id])

  async function defineExtract() {
    const historic = await MyAPI.getHistoricEmployee(props.dados_funcionario._id);
    const limite = props.dados_funcionario.ramicard.limite;

    if (historic.data.length > 0) {
      const monthlyExpenses = historic.data.filter((item) => item.situacao === 'Aberta')
        .reduce(
          (accumulator, currentValue) =>
            currentValue.valor / currentValue.parcelas +
            accumulator,
          0
        )


      const currentExtract = Number(limite - monthlyExpenses).toFixed(2);
      setExtract({ value: currentExtract, historic: historic.data });
    }
    if (!historic.data.length) {
      setExtract({ value: 0, historic: historic.data })

    }


  }

  // Callback para receber os dados do componente filho
  const receivePixData = (data) => {
    setDadosPix(data);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSuccessSnackBar(false);
  };

  const handleCNPJChange = debounce((value) => {
    const formattedCNPJ = Formatacao('CNPJ', value);
    setEmpresa((prev) => ({ ...prev, cnpj: formattedCNPJ }));
    if (formattedCNPJ.length === 18) {
      BuscarEmpresa(formattedCNPJ.replace(/\D/g, '')); // Remove formatação antes de enviar
    }
    BuscarEmpresa(formattedCNPJ); // Remove formatação antes de enviar
  }, 1000);

  useEffect(() => {
    setDadosPix({
      pixHabilitado: props.dados_funcionario.ramicard.pixHabilitado,
      taxaPix: props.dados_funcionario.ramicard.taxaPix,
      taxaPixParcelado: props.dados_funcionario.ramicard.taxaPixParcelado,
      maxParcelamento: props.dados_funcionario.ramicard.maxParcelamento,
    })
  }, [props.dados_funcionario])


  const [CEP, setCEP] = useState({
    cep: Formatacao('CEP', props.dados_funcionario.cep),
  });
  const [CPF, setCPF] = useState({
    cpf: Formatacao('CPF', props.dados_funcionario.cpf),
  });

  const [Celular, setCelular] = useState({
    celular: Formatacao('CEL', props.dados_funcionario.celular),
  });
  const [Telefone, setTelefone] = useState({
    telefone: Formatacao('TEL', props.dados_funcionario.telefone),
  });
  const [DataNascimento, setData] = useState({
    data: Formatacao('DATA', props.dados_funcionario.data_nascimento),
  });

  const [Empresa, setEmpresa] = useState({
    id: props.dados_funcionario.empresa_responsavel._id,
    cnpj: props.dados_funcionario.empresa_responsavel.cnpj,
    nome:
      props.dados_funcionario.empresa_responsavel?.nome_fantasia ||
      props.dados_funcionario.empresa_responsavel.razao_social,
    dia_fechamento: props.dados_funcionario.empresa_responsavel.dia_fechamento,
  });

  const [oldCompanie, setOldCompanie] = useState({
    id: props.dados_funcionario.empresa_responsavel._id,
    cnpj: props.dados_funcionario.empresa_responsavel.cnpj,
  });

  async function saveChanges(addDadosPix) {
    props.dados_funcionario.cep = CEP.cep.replace(/\D/g, '');
    props.dados_funcionario.cpf = CPF.cpf.replace(/\D/g, '');
    props.dados_funcionario.sexo = String(
      props.dados_funcionario.sexo
    )[0].toLocaleUpperCase();
    props.dados_funcionario.ramicard.limite = parseFloat(
      String(props.dados_funcionario.ramicard.limite)
        .replace('R$', '')
        .replace('.', '')
        .replace(',', '.')
    );
    if (dadosPix) {
      props.dados_funcionario.ramicard.pixHabilitado = dadosPix.pixHabilitado;
      props.dados_funcionario.ramicard.taxaPix = (dadosPix.taxaPix);
      props.dados_funcionario.ramicard.taxaPixParcelado = (dadosPix.taxaPixParcelado);
      // props.dados_funcionario.ramicard.percentualRotativo = (dadosPix.percentualRotativo);
      props.dados_funcionario.ramicard.maxParcelamento = dadosPix.maxParcelamento;
    }

    const CredenciadoID =
      props.dados_funcionario.empresa_responsavel?._id ||
      props.dados_funcionario.empresa_responsavel;

    try {
      const retorno = await api.post(
        `/administrativo/${CredenciadoID}/funcionario/${props.dados_funcionario._id}`,
        props.dados_funcionario,
        { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );
      if (retorno.data) {
        setOldCompanie({ id: Empresa.id, cnpj: Empresa.cnpj });
        setShowSuccessSnackBar(true)
      }
    } catch (err) {
      alert(err);
    }
  }

  const Atualizar = async (event) => {
    event.preventDefault();

    const message = `Deseja alterar a empresa deste funcionário de: ${oldCompanie.cnpj} para: ${Empresa.cnpj}?`;

    if (!(oldCompanie.cnpj === Empresa.cnpj)) {
      if (window.confirm(message)) {
        try {
          setLoading(true);
          await api.get(
            `/change-companie/${oldCompanie.id}/${Empresa.id}/${props.dados_funcionario._id}`,
            {
              headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
            }
          );
          setLoading(false);
        } catch (error) {
          alert(
            'Não foi possivel alterar as guias deste funcionário! Se o erro persistir contate o suporte.'
          );
        }

        saveChanges();
      } else {
        window.location.reload();
      }
    } else {
      saveChanges();
    }
  };

  const BuscarEmpresa = async (document) => {
    const object = { cnpj: `${document}`.replace(/\D/g, '') };

    if (object) {
      try {
        const { data } = await api.post(`/companie`, object, {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });

        setEmpresa({
          id: data._id,
          cnpj: data.cnpj,
          nome: data?.nome_fantasia || data.razao_social,
          dia_fechamento: data.dia_fechamento,
        });

        props.dados_funcionario.empresa_responsavel._id = data._id;
      } catch (error) {
        alert('Empresa não localizada!');
      }
    } else {
      alert('Preencha o campo Empresa Responsável');
    }
  };

  const Suspender = async (event) => {
    event.preventDefault();

    if (
      window.confirm(
        `Tem certeza que deseja suspender "${props.dados_funcionario.nome}"?`
      )
    ) {
      props.dados_funcionario.cep = CEP.cep.replace(/\D/g, '');
      props.dados_funcionario.cpf = CPF.cpf.replace(/\D/g, '');
      props.dados_funcionario.sexo = String(
        props.dados_funcionario.sexo
      )[0].toLocaleUpperCase();
      props.dados_funcionario.ramicard.limite = parseFloat(
        String(props.dados_funcionario.ramicard.limite)
          .replace('R$', '')
          .replace('.', '')
          .replace(',', '.')
      );

      try {
        if (!props.dados_funcionario.suspenso.status) {
          props.dados_funcionario.suspenso.motivo = `Suspenso em: ${data.getDate()}/${data.getMonth() + 1
          }/${data.getFullYear()} às ${data.getHours()}:${data.getMinutes()}`;
          props.dados_funcionario.suspenso.responsavel = `${String(
            localStorage.getItem('Name')
          )}`;
        } else {
          props.dados_funcionario.suspenso.motivo = `Reativado em: ${data.getDate()}/${data.getMonth() + 1
          }/${data.getFullYear()} às ${data.getHours()}:${data.getMinutes()}`;
          props.dados_funcionario.suspenso.responsavel = `${String(
            localStorage.getItem('Name')
          )}`;
        }

        props.dados_funcionario.suspenso.status =
          !props.dados_funcionario.suspenso.status;
        await api.post(
          `/administrativo/${typeof props.dados_funcionario.empresa_responsavel === 'object'
              ? props.dados_funcionario.empresa_responsavel._id
              : props.dados_funcionario.empresa_responsavel
          }/funcionario/${props.dados_funcionario._id}`,
          props.dados_funcionario,
          { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
        );
        window.location.reload();
      } catch (err) {
        alert('Ops! Ocorreu um erro.');
      }
    }
  };

  useEffect(() => setRefresh(false), [refresh]);

  const Ativo = (canal) => {
    const mudarStatus = (key, status) =>
      (props.dados_funcionario.ramicard.canais[key].habilitado = status);

    props.dados_funcionario.ramicard.canais.map((item, key) =>
      `${item.canal}` === String(canal)
        ? mudarStatus(key, !item.habilitado)
        : null
    );

    setRefresh(true);
  };

  async function calcularRecisao(cpf) {
    try {
      const { data } = await api.get(
        `https://7kwt37owscnmeeccvhrhrqbwu40acslu.lambda-url.sa-east-1.on.aws/rescission/${ OnlyNumbers(cpf)}`
      );

      if (data.error) {
        alert(data.message);
      } else {
        setDadosRecisao({...data, cpf});
        setTimeout(() => {
          setShowHideModalRecisao(true);
        }, 100);
      }

    } catch (error) {
      console.log(error);
    }
  }

  if (loading) {
    return (
      <Loading
        Description={`Aguarde... Estamos migrando todas as informações deste funcionário para ${Empresa.nome}!`}
      />
    );
  }

  return (
    <div className='content'>
      <RecisaoModal show={showHideModalRecisao} closeModal={() => setShowHideModalRecisao(false)} data={dadosRecisao  }/>
      <Grid fluid>
        <Row>
          <HistoricModal
            show={showHide}
            data={props.dados_funcionario}
            closeModal={() => setShowHide(false)}
          />
          <Col md={12}>
            <Card
              title={
                <>
                  Funcionário
                  <br />
                  <br />
                  <Button
                    onClick={props.voltar}
                    style={{
                      backgroundColor: '#008000',
                      color: '#FFF',
                      marginBottom: '20px',
                    }}
                    bsStyle='success'
                    simple={true}
                    type='button'
                  >
                    Voltar
                  </Button>
                  <CustomButton
                    onClick={() => setShowHide(true)}
                    content='Acessar histórico'
                  />
                  <CustomButton
                    onClick={() => calcularRecisao(props.dados_funcionario.cpf)}
                    content='Calcular Recisão'
                  />                
                </>
              }
              content={
                <form onSubmit={Atualizar}>
                  <FormInputs
                    {...props.dados_funcionario}
                    ncols={[
                      'col-md-1',
                      'col-md-3',
                      'col-md-3',
                      'col-md-3',
                      'col-md-2',
                    ]}
                    properties={[
                      {
                        disabled:
                          props.dados_funcionario.registro === undefined
                            ? false
                            : props.dados_funcionario.registro.length === 5
                            ? true
                            : false,
                        label: 'Registro',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'Código',
                        onChange: (event) =>
                          (props.dados_funcionario.registro =
                            event.target.value),
                        defaultValue: props.dados_funcionario.registro,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Funcionário',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'Nome Completo',
                        onChange: (event) =>
                          (props.dados_funcionario.nome = event.target.value),
                        defaultValue: Capitalize(props.dados_funcionario.nome),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'RG',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'RG',
                        onChange: (event) =>
                          (props.dados_funcionario.rg = event.target.value),
                        defaultValue: props.dados_funcionario.rg,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'CPF',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'CPF',
                        onChange: (event) =>
                          setCPF({
                            cpf: Formatacao('CPF', event.target.value),
                          }),
                        value: (props.dados_funcionario.cpf = CPF.cpf),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Nascido na data',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'Nascido na data',
                        onChange: (event) =>
                          setData({
                            data: Formatacao('DATA', event.target.value),
                          }),
                        value: (props.dados_funcionario.data_nascimento =
                          DataNascimento.data),
                      },
                    ]}
                  />

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Email
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Email'
                          disabled={props.dados_funcionario.suspenso.status}
                          onChange={(event) =>
                            (props.dados_funcionario.email = event.target.value)
                          }
                          defaultValue={props.dados_funcionario.email}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Telefone
                        </ControlLabel>
                        <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='Telefone'
                          onChange={(event) =>
                            setTelefone({
                              telefone: Formatacao('TEL', event.target.value),
                            })
                          }
                          value={
                            (props.dados_funcionario.telefone =
                              Telefone.telefone)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Celular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='Celular'
                          onChange={(event) =>
                            setCelular({
                              celular: Formatacao('CEL', event.target.value),
                            })
                          }
                          value={
                            (props.dados_funcionario.celular = Celular.celular)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1} align='Center'>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: '#000', textAlign: 'center' }}
                        >
                          Whatsapp
                        </ControlLabel>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <input
                            disabled={props.dados_funcionario.suspenso.status}
                            type='checkbox'
                            name='q1_myOptions[]'
                            id='input_1_4'
                            defaultChecked={props.dados_funcionario.whatsapp}
                            onClick={() =>
                              (props.dados_funcionario.whatsapp =
                                !props.dados_funcionario.whatsapp)
                            }
                          />
                          <label htmlFor='input_1_4'></label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' , display: 'block'}}>
                          Sexo
                        </ControlLabel>

                        <Radio name="radioSexo" inline
                          disabled={props.dados_funcionario.suspenso.status}
                          defaultChecked={props.dados_funcionario.sexo === 'M' || props.dados_funcionario.sexo === 'm' } 
                          onChange={(event) =>
                            (props.dados_funcionario.sexo = 'M')
                          }>
                          M
                        </Radio>{' '}
                        <Radio name="radioSexo" inline 
                          disabled={props.dados_funcionario.suspenso.status}
                          defaultChecked={props.dados_funcionario.sexo === 'F' || props.dados_funcionario.sexo === 'f' }                          
                          onChange={(event) =>
                            (props.dados_funcionario.sexo = 'F')
                          }>
                          F
                        </Radio>{' '}
                        

                        {/* <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='M/F'
                          onChange={(event) =>  
                            (props.dados_funcionario.sexo = event.target.value)
                          }
                          defaultValue={
                            ( props.dados_funcionario.sexo === 'F' || props.dados_funcionario.sexo === 'f' )
                              ? 'Feminino'
                              : props.dados_funcionario.sexo === undefined
                              ? ''
                              : 'Masculino'
                          }
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormInputs
                    ncols={[
                      'col-md-2',
                      'col-md-2',
                      'col-md-2',
                      'col-md-2',
                      'col-md-1',
                      'col-md-3',
                    ]}
                    properties={[
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'CEP',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'CEP',
                        onChange: (event) =>
                          setCEP({
                            cep: Formatacao('CEP', event.target.value),
                          }),
                        value: (props.dados_funcionario.cep = CEP.cep),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Cidade',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Cidade',
                        onChange: (event) =>
                          (props.dados_funcionario.cidade = event.target.value),
                        defaultValue: props.dados_funcionario.cidade,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Bairro',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Cidade',
                        onChange: (event) =>
                          (props.dados_funcionario.bairro = event.target.value),
                        defaultValue: props.dados_funcionario.bairro,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'UF',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Estado',
                        onChange: (event) =>
                          (props.dados_funcionario.estado = event.target.value),
                        defaultValue: props.dados_funcionario.estado,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Nº',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Numero',
                        onChange: (event) =>
                          (props.dados_funcionario.numero = event.target.value),
                        defaultValue: props.dados_funcionario.numero,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Endereço',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Endereço',
                        onChange: (event) =>
                          (props.dados_funcionario.endereco =
                            event.target.value),
                        defaultValue: props.dados_funcionario.endereco,
                      },
                    ]}
                  />

                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Limite do Cartão
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Limite do Cartão'
                          onChange={(event) =>
                            (props.dados_funcionario.ramicard.limite =
                              event.target.value)
                          }
                          defaultValue={Number(
                            props.dados_funcionario.ramicard.limite
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Limite disponível
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          value={Number(extract.value).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Percentual Rotativo
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='% rotativo'
                          onChange={(event) => {
                            const inputValue = event.target.value.replace('%', '').trim();
                            const numericValue = parseFloat(inputValue) / 100;
                            props.dados_funcionario.ramicard.percentualRotativo = isNaN(numericValue) ? 0 : numericValue;
                          }
                          }
                          defaultValue={Number(
                            typeof props.dados_funcionario.ramicard.percentualRotativo != "number" ? isNaN(props.dados_funcionario.ramicard.percentualRotativo) ? 0 : props.dados_funcionario.ramicard.percentualRotativo * 100 : props.dados_funcionario.ramicard.percentualRotativo
                          ).toLocaleString('pt-br', {
                            style: 'percent',
                          })}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Valor de Rescisão
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Valor de Rescisão'
                          defaultValue='R$ 0,00'
                        />
                      </FormGroup>
                    </Col> */}

                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Empresa Responsável
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          required={true}
                          placeholder='CNPJ da Empresa'
                          onChange={(event) => handleCNPJChange(event.target.value)}
                          // value={Empresa.cnpj}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Empresa Selecionada
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome da Empresa'
                          value={Empresa.nome}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Fechamento
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Dia'
                          onChange={(event) =>
                            (props.dados_funcionario.empresa_responsavel =
                              event.target.value)
                          }
                          value={Empresa.dia_fechamento}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {props.dados_funcionario.ramicard.canais !== undefined ? (
                    <Card
                      title='Canais liberados pela empresa'
                      content={props.dados_funcionario.ramicard.canais.map(
                        (item, key) => (
                          <Row key={key}>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Nome do Serviço
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Nome do Serviço'
                                  value={item.canal}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Valor liberado
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder=''
                                  disabled={true}                                  
                                  defaultValue={item.limite_consumo}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Parcelamento máx.
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Parcelas'
                                  disabled={!item.habilitado || item.canal == 'Ramicash'}
                                  onChange={(event) =>
                                    (props.dados_funcionario.ramicard.canais[
                                      key
                                    ].parcelas_max = event.target.value)
                                  }
                                  defaultValue={item.parcelas_max}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup align='center'>
                                <ControlLabel style={{ color: '#000' }}>
                                  Habilitado
                                </ControlLabel>
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type='checkbox'
                                    defaultChecked={item.habilitado}
                                    onChange={() => Ativo(item.canal)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      )}
                    />
                  ) : (
                    <Card
                      title='Canais liberados pela empresa'
                      content={
                        <Row>
                          <Col md={12}>
                            Empresa responsável não foi selecionada!
                          </Col>
                        </Row>
                      }
                    />
                  )}

                  <Row>
                    <Col md={12}>
                      <Card
                        title='Configurações para transações via pix'
                        content={
                          <>
                            <PixSettingsFormByEmployee initialState={dadosPix} sendDataToParent={receivePixData} actionButtons={true} />
                          </>
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <FormGroup controlId='formControlsTextarea'>
                        <ControlLabel style={{ color: '#000' }}>
                          Observações
                        </ControlLabel>
                        <FormControl
                          disabled={props.dados_funcionario.suspenso.status}
                          rows='3'
                          componentClass='textarea'
                          bsClass='form-control'
                          placeholder='Anotações'
                          onChange={(event) =>
                            (props.dados_funcionario.observacoes =
                              event.target.value)
                          }
                          defaultValue={props.dados_funcionario.observacoes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={ ! checkPermissoes(nomePermissao, 'update', `${props.dados_funcionario.empresa_responsavel.cnpj}` ) }
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Atualizar
                  </Button>
                  <Button
                    onClick={Suspender}
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    {!props.dados_funcionario.suspenso.status
                      ? 'Suspender'
                      : 'Ativar'}
                  </Button>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>

      <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar}>
        <Alert onClose={handleCloseSuccessSnackbar} severity="success">
          <span style={{ fontSize: '1.5rem' }}>Dados atualizados com sucesso.</span>
        </Alert>
      </Snackbar>
    </div>
  );
};
