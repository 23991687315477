import React, { useState, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Button } from 'react-bootstrap';

function DateRangePicker({ onFilterApply }) {
  const [selectedInitialDate, setSelectedInitialDate] = useState(moment().startOf('month'));
  const [selectedFinalDate, setSelectedFinalDate] = useState(moment().endOf('month'));

  // Função para aplicar o filtro sempre que a data for alterada
  const applyFilter = () => {
    onFilterApply({
      startDate: selectedInitialDate.startOf('day').toISOString(),
      endDate: selectedFinalDate.endOf('day').toISOString(),
    });
  };

  // Função para lidar com a mudança na data inicial
  const handleInitialDateChange = (date) => {
    if (date.isAfter(selectedFinalDate)) {
      setSelectedFinalDate(date); // Ajusta a data final se a inicial for maior
    }
    setSelectedInitialDate(date);
  };

  // Função para lidar com a mudança na data final
  const handleFinalDateChange = (date) => {
    if (date.isBefore(selectedInitialDate)) {
      setSelectedInitialDate(date); // Ajusta a data inicial se a final for menor
    }
    setSelectedFinalDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="date-picker-initial"
          label="DE"
          value={selectedInitialDate}
          onChange={handleInitialDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="date-picker-final"
          label="ATÉ"
          value={selectedFinalDate}
          onChange={handleFinalDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <Button onClick={applyFilter} variant="primary">Buscar</Button>


      </div>
    </MuiPickersUtilsProvider>
  );
}

export default DateRangePicker;
