
import api from "../../../../services/api.jsx"
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from "../../../../components/CustomButton/CustomButton.jsx";

import MyAPI from "../../../../connections/Api.jsx";
import { getToken } from "../../../../services/autenticacoes.jsx"

const validationSchema = Yup.object().shape({
    parcelas_max: Yup.number()
        .min(1, 'Deve ser no mínimo 1 parcela')
        .max(36, 'Deve ser no máximo 36 parcelas')
        .required('Número máximo de parcelas é obrigatório'),
});

export default function PixSettingsFormByCompany({ id, actionButtons, initialState, ...props }) {
    const [dadosPix, setDadosPix] = useState({});
    const [open, setOpen] = useState(false);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            parcelas_max: 10,
        },
        resolver: yupResolver(validationSchema),
    });

    const areAllValuesDefined = (obj) => {
        return Object.values(obj).every(value => value !== undefined);
    }

    useEffect(() => {
        if (areAllValuesDefined(initialState)) {
            let data = {
                parcelas_max: initialState.parcelas_max,
            }
            reset({ parcelas_max : initialState.parcelas_max });
        } else {
            reset({
                parcelas_max: 10,
            })
        }
    }, [initialState, reset]);

    const onSubmit = async (data) => {
        const response = await api.post(`/administrativo/empresa/${id}/pix`, data, { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } });
    };

    const handleReset = () => {
        reset();
    };

    const updateAllPixSettings = async () => {
          try {
             const response = await MyAPI.postUpdateAllPixSettings({id_empresa: id}, { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } });
    
             alert(response.data.message)
          } catch (e) {
             console.log(e)
          }
       }

    return (
        <form style={{ margin: '10px' }}>
            <Row>
                <FormGroup>
                    <ControlLabel style={{ color: '#000' }}>
                        Quantidade máxima de parcelas
                    </ControlLabel>

                    <Controller
                        name="parcelas_max"
                        control={control}
                        render={({ field }) => (
                            <FormControl
                                type='text'
                                {...field}
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                                bsClass='form-control'
                                placeholder='Quantidade máxima de parcelas'
                            // disabled={true}
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            {/* <Row>
                <FormGroup>
                    <ControlLabel style={{ color: '#000' }}>
                        Habilitado
                    </ControlLabel>
                    <div
                        style={{
                            marginTop: '10px',
                            marginLeft: '10px',
                        }}
                    >
                        <Controller
                            name="ativo"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type='checkbox'
                                    checked={field.checked}
                                    onChange={(e) => field.onChange(e.target.value)}
                                />
                            )}
                        />
                    </div>
                </FormGroup>
            </Row> */}
            {
                actionButtons && (
                    <>
                        <Button
                            variant='contained'
                            // type='submit'
                            onClick={handleSubmit(onSubmit)}
                            bsStyle='info'
                            style={{
                                marginTop: '35px',
                                marginRight: '10px',
                                background: 'rgba(35,142,35, .8)',
                                border: 0,
                                borderRadius: 3,
                                boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                color: 'white',
                                fontSize: '14px',
                            }}
                        >
                            {' '}
                            Cadastrar
                        </Button>
                        <Button
                            variant='contained'
                            onClick={updateAllPixSettings}
                            bsStyle='info'
                            style={{
                                marginTop: '35px',
                                marginRight: '10px',
                                background: 'rgba(35,142,35, .8)',
                                border: 0,
                                borderRadius: 3,
                                boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                color: 'white',
                                fontSize: '14px',
                            }}
                        >
                            {' '}
                            Definir a todos os funcionários
                        </Button>
                    </>
                )
            }
        </form>
    )
}